import { Box, FormControlLabel, Grid, Radio, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IdCard } from '../../../assets/images/id-card.svg';
import { ReactComponent as Camera } from '../../../assets/images/liveness-check.svg';
import { ReactComponent as Location } from '../../../assets/images/proof_of_address.svg';
import KYCProcedureService from '../../../repository/KYCProcedureService';
import { KycButton } from '../../../shared/styled-components/KycButton';
import { ProcedureType } from '../constants/ProcedureType';
import { IdentityVerificationStepsProps } from './IdentityVerificationStepsProps';
import './IdentityVerificatiobSteps.scss';

function IdentityVerificationSteps(props: IdentityVerificationStepsProps) {
  const { t } = useTranslation();
  const [procedureType, setProcedureType] = useState<ProcedureType | null>(null);

  const createProcedure = async () => {
    KYCProcedureService.existsByToken(props.token!).then((res) => {
      if (!res) {
        KYCProcedureService.createKYCProcedure(props.token!).then((res) => {
          if (procedureType === ProcedureType.INDIVIDUAL) {
            KYCProcedureService.setIndividualType(res.procedureId);
          } else if (procedureType === ProcedureType.LEGAL_ENTITY) {
            KYCProcedureService.setLegalEntityType(res.procedureId);
          }
          props.onNextStep();
        });
      }
    });
  };

  const handleChangeVerificationAnswer = (event: SelectChangeEvent) => {
    setProcedureType(event.target.value);
    props.onProcedureTypeChange(event.target.value);
  };

  return <Grid container className='middle-content' rowSpacing={2}>
    <Grid item xs={12}>
        <Typography variant='body1' className='verification-description'>
          {t('IDENTITY_VERIFICATION')}
        </Typography>
    </Grid>
    <Grid container item xs={12} spacing={4} className='steps-container'>
      <Grid item lg={4} xs={12} className='verification-step-item'>
        <Box className='verification-step'>
          <IdCard/>
          <Typography variant='body1' className='step-title'>{t('ID_DOCUMENT')}</Typography>
          <Typography className='card-content'>{t('IDENTIFICATION_DOCUMENT')}</Typography>
        </Box>
      </Grid>
      <Grid item lg={4} xs={12} className='verification-step-item'>
        <Box className='verification-step'>
          <Camera/>
          <Typography variant='body1' className='step-title'>{t('LIVENESS_SCAN')}</Typography>
          <Typography className='card-content'>{t('LIVENESS_SCAN_INFO')}</Typography>
        </Box>
      </Grid>
      <Grid item lg={4} xs={12} className='verification-step-item'>
        <Box className='verification-step'>
          <Location/>
          <Typography variant='body1' className='step-title' fontWeight={600}>{t('PROOF_OF_ADDRESS')}</Typography>
          <Typography className='card-content'>{t('PROOF_OF_ADDRESS_INFO')}</Typography>
        </Box>
      </Grid>
    </Grid>

    <Grid item xs={12} justifyContent='center'>
      <Typography className='start_verification_question'>{t('INDIVIDUAL_OR_LEGAL_ENTITY_MESSAGE')}</Typography>
      <RadioGroup className='start_verification_question_answers' row
                  value={procedureType}
                  onChange={handleChangeVerificationAnswer}
                  name='radio-buttons-group'>
        <FormControlLabel value={ProcedureType.INDIVIDUAL} control={<Radio/>} label={t('INDIVIDUAL')}/>
        <FormControlLabel value={ProcedureType.LEGAL_ENTITY} control={<Radio/>} label={t('LEGAL_ENTITY')}/>
      </RadioGroup>
    </Grid>
    <Grid item xs={12} className='flex-center'>
      <KycButton variant='contained'
                 color='primary'
                 disabled={procedureType === null}
                 onClick={createProcedure} sx={{ mt: 1 }}>
        {t('START_VERIFICATION')}
      </KycButton>
    </Grid>
  </Grid>;
}

export default IdentityVerificationSteps;
