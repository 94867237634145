import { CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KycCard from '../../shared/components/KycCard/KycCard';
import MainPage from '../../shared/pages/main-page/MainPage';
import './kyc-submitted-page.scss';

function KycSubmittedPage() {
  const { t } = useTranslation();

  return <MainPage>
    <KycCard id='kyc-submitted-card'>
      <CardContent>
        <Stack id='kyc-submitted-page'>
          <Typography variant='h2' className='title'>
            {t('KYC_SUCCESSFULLY_SUBMITTED')}
          </Typography>
          <Typography variant='h6' className='description'>
            {t('KYC_SUBMITTED_SUCCESSFULLY_MESSAGE')}
          </Typography>
        </Stack>
      </CardContent>
    </KycCard>
  </MainPage>;
}

export default KycSubmittedPage;
