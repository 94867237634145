export class CountryDto {
  id: number;
  name: string;
  countryRisk: string | undefined;
  shortCode: string;
  prefixNumber: string;

  constructor(json: CountryDto) {
    this.id = json.id;
    this.name = json.name;
    this.countryRisk = json.countryRisk;
    this.shortCode = json.shortCode;
    this.prefixNumber = json.prefixNumber;
  }
}
