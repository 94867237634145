import axios from '../axios/axios';

export class ClientService {

  static async getMessage(token: string): Promise<any> {
    const response = await axios.get('/communication-message/public/message', { params: { token } });
    return response.data;
  }

  static async answerToMRLO(data: any) {
    return await axios.post('/client/public/answer', data);
  }
}