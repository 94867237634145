
import axios from "../axios/axios";
import {LegalEntityFormDto} from "../models/legal_entity/LegalEntityFormDto";

const LegalEntityFormService = {
    async allLegalEntityForms(): Promise<LegalEntityFormDto[]> {
        const response = await axios.get('/legal-entity-form/public/list-all');
        return response.data;
    }
};

export default LegalEntityFormService;
