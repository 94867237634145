import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';

interface CheckboxInputProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: any;
  isChecked: boolean;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  name,
  register,
  watch,
  onChange,
  value,
  isChecked
}) => {
  const onHandleEvent = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...register(name)}
          color={'primary'}
          checked={isChecked}
          onChange={onHandleEvent}
          icon={<CheckBoxOutlineBlank color={'primary'}/>}
          value={value}/>
      }
      label={<>{label}</>}/>
  );
};

export default CheckboxInput;
