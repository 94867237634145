import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './footer.scss';

function Footer() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return <Stack direction={isSmallScreen ? 'column': 'row'} className={`footer ${isSmallScreen ? 'centered' : ''}`}>
    <Typography className='rights-reserved'>{t('ALL_RIGHTS_RESERVED')}</Typography>
    <Stack direction={isSmallScreen ? 'column': 'row'} gap={2}>
      <Link to={''}>{t('PRIVACY_POLICY')}</Link>
      <Link to={''}>{t('TERMS_OF_SERVICE')}</Link>
      <Link to={''}>{t('COOKIES_POLICY')}</Link>
    </Stack>
  </Stack>
}

export default Footer;
