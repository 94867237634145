import axios from '../axios/axios';
import ClientQuestionnairesDTO from '../models/clientQuestionnaires/ClientQuestionnairesDTO';
import AMLQuestionAnswerDTO from '../models/clientQuestionnaires/AMLQuestionAnswerDTO';

export class AMLQuestionService {
  static async getAMLPersonGroupQuestions(clientToken: string | undefined): Promise<ClientQuestionnairesDTO[]> {
    const response = await axios.post(
      '/metamodel/aml-question/public/get-person-questions',
      {},
      {
        params: { clientToken }
      }
    );
    return response.data;
  }

  static async createAMLProcedure(amlQuestionAnswerDTO: AMLQuestionAnswerDTO[], tokenParam: string | undefined) {
    await axios.post(
      '/metamodel/aml-question/public/aml-answers',
      amlQuestionAnswerDTO,
      {
        params: { tokenParam }
      }
    );
  }
}
