import { Box } from '@mui/material';
import {ReactComponent as DigiscanLogo } from '../../assets/images/digiscan.svg';
import './header.scss';

function Header() {
  return <Box className='header'>
    <DigiscanLogo/>
  </Box>
}

export default Header;
