import { DeleteOutline } from '@mui/icons-material';
import { Grid, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShareholderRoleDto } from '../../../models/legal_entity/ShareholderRoleDto';
import { ShareholderTypeDto } from '../../../models/legal_entity/ShareholderTypeDto';
import ShareholderService from '../../../repository/ShareholderService';
import { KycSelectInputLabel } from '../../../shared/styled-components/KycSelectInputLabel';
import { ShareholderProps } from './ShareholderProps';
import './Shareholder.scss';

export function Shareholder(
  { shareholder, index, onChange, removeItem }: ShareholderProps
) {
  const { t } = useTranslation();
  const [shareholderTypes, setShareholderTypes] = useState<ShareholderTypeDto[]>([]);
  const [shareholderRoles, setShareholderRoles] = useState<ShareholderRoleDto[]>([]);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    ShareholderService.allShareholderTypes().then((res) => {
      setShareholderTypes(res);
    });
    ShareholderService.allShareholderRoles().then((res) => {
      setShareholderRoles(res);
    });
  }, []);

  const handleChangeShareholderType = (event: SelectChangeEvent) => {
    onChange(index, { ...shareholder, shareholderType: event.target.value });
  };

  const handleChangeShareholderRole = (event: SelectChangeEvent) => {
    onChange(index, { ...shareholder, shareholderRole: event.target.value });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, { ...shareholder, email: event.target.value });
    if (event.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleFirstNameChange = (event: any) => {
    onChange(index, { ...shareholder, firstName: event.target.value });
  };

  const handleLastNameChange = (event: any) => {
    onChange(index, { ...shareholder, lastName: event.target.value });
  };

  const handlePhoneNumberChange = (event: any) => {
    onChange(index, { ...shareholder, phoneNumber: event.target.value });
  };

  const handlePercentageOfSharesChange = (event: any) => {
    let value = parseInt(event.target.value, 10);

    if (value < 0) value = 0;
    if (value > 100) value = 100;

    onChange(index, { ...shareholder, percentageOfShares: value.toString() });
  };

  const handleCompanyNameChange = (event: any) => {
    onChange(index, { ...shareholder, companyName: event.target.value });
  };

  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={10}>
        <Typography variant='body1' fontWeight={600}>{`${t('SHAREHOLDER')} ${index + 1}`}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={t('REMOVE_SHAREHOLDER')}>
          <IconButton onClick={() => removeItem(index)} className={'shareholder-remove-icon'} color='error'>
            <DeleteOutline/>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <TextField
          label={t('FIRST_NAME')}
          fullWidth={true}
          value={shareholder.firstName}
          onChange={handleFirstNameChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <TextField
          label={t('LAST_NAME')}
          fullWidth={true}
          value={shareholder.lastName}
          onChange={handleLastNameChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('EMAIL')}
          fullWidth={true}
          error={emailError}
          helperText={emailError && t('EMAIL_CORRECT_FORMAT')}
          inputProps={{
            type: 'email'
          }}
          value={shareholder.email}
          onChange={handleEmailChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('COMPANY_NAME') + " (" + t('OPTIONAL') +")"}
          fullWidth={true}
          value={shareholder.companyName}
          onChange={handleCompanyNameChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('PHONE_NUMBER')}
          fullWidth={true}
          type='number'
          value={shareholder.phoneNumber}
          onChange={handlePhoneNumberChange}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <FormControl fullWidth>
          <KycSelectInputLabel>{t('SHAREHOLDER_TYPE')}</KycSelectInputLabel>
          <Select
            id='shareholderType'
            value={shareholder.shareholderType}
            label={t('SHAREHOLDER_TYPE')}
            onChange={handleChangeShareholderType}
          >
            {
              shareholderTypes.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <FormControl fullWidth>
          <KycSelectInputLabel>{t('SHAREHOLDER_ROLE')}</KycSelectInputLabel>
          <Select
            id='shareholderRole'
            value={shareholder.shareholderRole}
            label={t('SHAREHOLDER_ROLE')}
            onChange={handleChangeShareholderRole}
          >
            {
              shareholderRoles.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('PERCENTAGE_OF_SHARING')}
          fullWidth={true}
          value={shareholder.percentageOfShares}
          type='number'
          inputProps={{ min: 0, max: 100 }}
          onChange={handlePercentageOfSharesChange}/>
      </Grid>
    </Grid>
  );
}
