import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';

interface FullPageLoadingCircleProps {
  loading: boolean;
  handleClose?: () => void;
}

export function FullPageLoadingCircle(props: FullPageLoadingCircleProps) {
  const { t } = useTranslation();

  return props.loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.loading}
    >
      <div className="flex-column">
        <CircularProgress size={60} style={{ color: "#3f51b5" }} />
        <br />
        {props.handleClose && (
          <Button
            onClick={props.handleClose}
            style={{ color: "whitesmoke", marginLeft: "15px" }}
            sx={{ m: 2 }}
          >
            {t('CANCEL')}
          </Button>
        )}
      </div>
    </Backdrop>
  ) : (
    <></>
  );
}
