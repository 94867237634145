import { CardContent, MobileStepper, Stack, Step, StepButton, Stepper, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FullPageLoadingCircle } from '../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import { CountryDto } from '../../models/admin/CountryDto';
import AMLQuestionAnswerDTO from '../../models/clientQuestionnaires/AMLQuestionAnswerDTO';
import ClientQuestionnairesDTO from '../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import { AMLQuestionService } from '../../repository/AMLQuestionService';
import { ClientTokenService } from '../../repository/ClientTokenService';
import CountryService from '../../repository/CountryService';
import { ProcedureService } from '../../repository/ProcedureService';
import KycCard from '../../shared/components/KycCard/KycCard';
import MainPage from '../../shared/pages/main-page/MainPage';
import { KycButton } from '../../shared/styled-components/KycButton';
import { ErrorHandler } from '../../utils/ErrorHandler';
import './AMLProcedure.scss';
import { AMLQuestion, AmlQuestionHandle } from './AMLQuestion/AMLQuestion';
import { AMLVerification } from './AMLVerification/AMLVerification';

export function AMLProcedure() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const [questions, setQuestions] = useState<ClientQuestionnairesDTO[]>([]);
  const { token } = useParams();
  const { register, watch } = useForm();
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);
  const [answerOnQuestions, setAnswerOnQuestion] = useState<AMLQuestionAnswerDTO[]>([]);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [loading, setLoading] = useState(false);
  const questionRef = useRef<AmlQuestionHandle>(null);
  const [actionsDisabled, setActionsDisabled] = useState<boolean>(true);
  const isLastQuestion = activeStep === questions.length - 1;

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllCountries = () => {
    CountryService.getAllCountries()
      .then(response => setCountries(response.data));
  };

  const handleVerificationComplete = () => {
    setIsVerificationCompleted(true);
  };

  const navigateToCompletedProcedure = () => navigate('/aml-submitted');

  const validateToken = async (token: string) => {
    await ClientTokenService.validateClientToken(token)
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        const customMessage = t('START_NEW_AML_PROCEDURE');
        SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
      });
  };

  const getPersonQuestions = () => {
    AMLQuestionService.getAMLPersonGroupQuestions(token).then(
      (response: ClientQuestionnairesDTO[]) => {
        setQuestions(response);
      }
    );
  };
  useEffect(() => {
    if (!token) {
      navigate('/error');
      return;
    }
    ProcedureService.existsAMLProcedureByClientToken(token).then((res) => {
      if (!res) {
        validateToken(token).then(() => {
          getPersonQuestions();
        });
      } else {
        navigateToCompletedProcedure();
      }
    });
  }, []);

  const handleStep = (step: number) => () => {
    if (answerOnQuestions[step] !== undefined) {
      setActiveStep(step);
    }
  };
  const checkIfAnswered = (answer: AMLQuestionAnswerDTO) => {
    return !!((answer?.files && answer.files?.length > 0)
      || (answer?.countries && answer.countries?.length > 0)
      || answer?.freeTextAnswer
      || (answer?.nomenclatureEntryIds && answer.nomenclatureEntryIds.length > 0));

  };

  const handleSaveAnswer = (index: number | string, amlAnswer: AMLQuestionAnswerDTO) => {
    if (typeof index == 'string') {
      index = parseInt(index);
    }
    answerOnQuestions[index] = amlAnswer;
    setAnswerOnQuestion([...answerOnQuestions]);
  };

  const handleSubmitAmlProcedure = () => {
    setLoading(true);
    AMLQuestionService.createAMLProcedure(answerOnQuestions, token)
      .then(() => {
        setLoading(false);
        navigateToCompletedProcedure();
      }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      setLoading(false);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };

  const handleSaveAnswerAndGoBack = async () => {
    const answer = await questionRef!.current!.getAmlQuestionAnswer();
    handleSaveAnswer(activeStep, answer);
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleSaveAnswerAndGoNext = async () => {
    const answer = await questionRef!.current!.getAmlQuestionAnswer();
    handleSaveAnswer(activeStep, answer);
    if (activeStep === questions.length - 1) {
      handleSubmitAmlProcedure();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const renderActiveQuestion = () => {
    const question = questions[activeStep];
    return <AMLQuestion ref={questionRef} key={activeStep}
                        question={question.amlQuestionDTO}
                        type={question?.amlQuestionDTO?.amlQuestionType}
                        index={activeStep}
                        sizeQuestion={questions.length}
                        answers={question.nomenclatureEntryDtoList}
                        description={question.amlQuestionDTO.description}
                        answerOnQuestion={
                          answerOnQuestions && answerOnQuestions[activeStep]
                        }
                        files={question.amlQuestionFileDtos}
                        register={register}
                        watch={watch}
                        onActionsDisabledChange={(actionsDisabled) => setActionsDisabled(actionsDisabled)}
                        countries={countries}/>
  }

  const renderMobileStepper = () => {
    return <Stack sx={{ padding: '0 1rem', flex: 1 }}>
      {renderActiveQuestion()}
      <MobileStepper
        variant="text"
        steps={questions.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <KycButton variant='contained'
            size="small" className='small-button'
            onClick={handleSaveAnswerAndGoNext}
            disabled={actionsDisabled}>
            {isLastQuestion ? t('SUBMIT'): t('NEXT')}
          </KycButton>
        }
        backButton={
          <KycButton size="small" variant='contained' onClick={handleSaveAnswerAndGoBack} disabled={activeStep === 0} className='small-button'>
            {t('BACK')}
          </KycButton>
        }
      />
    </Stack>
  }

  const renderHorizontalStepper = () => {
    return <>
      {renderActiveQuestion()}
      <Stack direction='row'>
        {activeStep > 0 && <KycButton variant='contained' onClick={handleSaveAnswerAndGoBack}>{t('BACK')}</KycButton>}
        <Stepper sx={{ flex: 1 }}
                 nonLinear
                 activeStep={activeStep}
                 className='aml-stepper' connector={null}>
          {questions.map((_: any, index: number) => (
            <Step key={index} completed={checkIfAnswered(answerOnQuestions[index])}>
              <StepButton color='inherit' onClick={handleStep(index)}/>
            </Step>
          ))}
        </Stepper>
        <KycButton variant='contained'
                onClick={handleSaveAnswerAndGoNext}
                disabled={actionsDisabled}>
          {isLastQuestion ? t('SUBMIT'): t('NEXT')}
        </KycButton>
      </Stack>
    </>
  }

  return <MainPage>
    <KycCard>
      <CardContent className='aml-procedure-card'>
        {!isVerificationCompleted ? (
          <AMLVerification onNext={handleVerificationComplete}/>
        ) : (
          <Stack className='aml-question-wrapper' spacing={2}>
            <Typography variant='h2' id='step-title'>
              {t('QUESTION')} {activeStep + 1} / {questions.length}
            </Typography>
            { isSmallScreen ? renderMobileStepper() : renderHorizontalStepper() }
          </Stack>
        )}
      </CardContent>
    </KycCard>
    <FullPageLoadingCircle loading={loading}/>
  </MainPage>;
}
