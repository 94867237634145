import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AMLProcedure } from '../pages/AMLProcedure/AMLProcedure';
import KycSubmittedPage from '../pages/KycSubmittedPage/KycSubmittedPage';
import ClientMlroConversation from '../pages/Clients/active-procedure/client-conversation/ClientMlroConversation';
import AmlSubmittedPage from '../pages/AmlSubmittedPage/AmlSubmittedPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import SuccessfulDocumentUpload from '../pages/SuccessfulDocumentUpload/SuccessfulDocumentUpload';
import PdfViewer from "../shared/components/Pdf-Viewer/PdfViewer";

const RoutesComponents = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='landing/:token' element={<LandingPage/>}/>
        <Route path='aml/:token' element={<AMLProcedure/>}/>
        <Route path='procedure-communication' element={<ClientMlroConversation/>}/>
        <Route path='/document-upload-success' element={<SuccessfulDocumentUpload/>}/>
        <Route path='/view-pdf/:fileName/view' element={<PdfViewer/>}/>
        <Route path='/kyc-submitted' element={<KycSubmittedPage/>}/>
        <Route path='/aml-submitted' element={<AmlSubmittedPage/>}/>
      </Routes>
    </BrowserRouter>
  );
};
export default RoutesComponents;
