import axios from "../axios/axios";

const GBGTokenService = {
  generateScanningToken: async () => {
    return await axios
      .post("/GBG/public/scanning-token")
      .then((response) => response.data.token);
  },

  updateProofOfAddress: async (data: any, token: string) => {
    return await axios.put(`/public/kyc-procedure/proof-of-address?token=${token}`, data);
  }

};

export default GBGTokenService;
