import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import './App.scss';
import { themeOptions } from './config/KycTheme';
import RoutesComponents from './routes/RoutesComponents';
import './i18n';

const theme = createTheme(themeOptions);

function App() {

  return (
      <ThemeProvider theme={theme}>
          <RoutesComponents/>
      </ThemeProvider>
  );
}

export default App;
