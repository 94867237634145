import { ThemeOptions } from '@mui/material/styles/createTheme';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#4760FF',
      light: '#667aff',
      dark: '#1a38ff'
    },
    secondary: {
      main: '#002149',
      light: '#002e66',
      dark: '#001733',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#010101'
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100'
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b'
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Bai Jamjuree, sans-serif',
    fontWeightMedium: 400,
    h6: {
      fontSize: '1.25rem'
    },
    h5: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '2.5rem'
    },
    h2: {
      fontSize: '3rem'
    },
    h1: {
      fontSize: '4rem'
    },
    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '0.875rem'
    },
    caption: {
      fontSize: '0.75rem',
      color: '#A4ABB3'
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'filled'
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          '&::before': {
            borderBottom: 0
          },
          '&:hover:not(.Mui-disabled, .Mui-error)::before': {
            borderBottom: 0
          },
          '&::after': {
            borderBottom: 0
          },
          '.MuiFilledInput-input': {
            borderRadius: 'inherit'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        variant: 'filled'
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: '30px',
        }
      }
    }
  }
};
