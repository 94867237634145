import axios from '../axios/axios';
import { ShareholderRoleDto } from '../models/legal_entity/ShareholderRoleDto';
import { ShareholderTypeDto } from '../models/legal_entity/ShareholderTypeDto';

const ShareholderService = {
  async allShareholderTypes(): Promise<ShareholderTypeDto[]> {
    const response = await axios.get('/shareholder/public/all-types');
    return response.data;
  },
  async allShareholderRoles(): Promise<ShareholderRoleDto[]> {
    const response = await axios.get('/shareholder/public/all-roles');
    return response.data;
  }
};

export default ShareholderService;
