import Swal from "sweetalert2";
const SwalAlert: any = {
  successfulAlert: (title: string, text: string): void => {
    Swal.fire({
      title: title,
      text: text,
      icon: "success",
      confirmButtonColor: "#0e387a",
    });
  },
  errorAlert: (title: string, text: string): void => {
    Swal.fire({
      icon: "error",
      confirmButtonColor: "#0e387a",
      title: title,
      text: text,
    });
  },

  confirmationAlert: (title: string, text: string) => {
    return Swal.fire({
      icon: "question",
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonColor: "#0e387a",
      confirmButtonText: "Accept",
      showDenyButton: true,
      denyButtonText: "Decline",
    });
  },
};
export default SwalAlert;
