import { KycProcedureStep } from '../interfaces/KycProcedureStep';
import { LandingPageStep } from '../LandingPageStep';

export const legalEntityKycSteps: KycProcedureStep[] = [
  {
    step: 0,
    label: 'IDENTITY_VERIFICATION_STEPS',
    type: LandingPageStep.IDENTITY_VERIFICATION
  },
  {
    step: 1,
    label: 'LEGAL_ENTITY_INFO',
    type: LandingPageStep.LEGAL_ENTITY_ANSWERS
  },
  {
    step: 2,
    label: 'ID_DOCUMENT',
    type: LandingPageStep.ID_DOCUMENT
  },
  {
    step: 3,
    label: 'PROOF_OF_ADDRESS',
    type: LandingPageStep.PROOF_OF_ADDRESS
  },
  {
    step: 4,
    label: 'INPUT_SHAREHOLDERS',
    type: LandingPageStep.SHAREHOLDERS
  },
  {
    step: 5,
    label: 'TERMS_AND_CONDITIONS',
    type: LandingPageStep.TERMS_AND_CONDITIONS
  }
];
